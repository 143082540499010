<template>
  <div class="container">
    <div v-if="tableType === 'detail'" class="container-title mb-10">
      <h2>{{ configData.configName }}</h2>
      <a-button size="default" icon="double-left" @click="getBack">返回列表</a-button>
    </div>
    <div class="container-header mb-20">

      <div>
        <a-input v-model="parameter.configName" placeholder="字典名称" allow-clear class="w-200 mr-10" />
        <a-input v-model="parameter.configCode" placeholder="字典code" allow-clear class="w-200 mr-10" />
        <a-auto-complete
          v-if="tableType === 'list'"
          v-model="parameter.enterpriseId"
          :filterOption="filterOption"
          placeholder="请选择企业名称"
          class="mr-10"
          allow-clear
        >
          <template slot="dataSource">
            <a-select-option
              v-for="(enterpriseName, enterpriseId) in enterpriseList"
              :key="enterpriseId"
              :value="enterpriseId + ''"
              :label="enterpriseName"
            >
              {{ enterpriseName }}
            </a-select-option>
          </template>
        </a-auto-complete>
        <a-button type="primary" icon="search" class="mr-10" @click="search(parameter)">查询</a-button>
        <a-button icon="redo" @click="reset">重置</a-button>
      </div>
      <div>
        <a-button type="primary" icon="plus" @click="addDictionary">新增</a-button>
      </div>

    </div>
    <div>
      <a-table :columns="columns" :data-source="configList" :row-key="tableType === 'list'?'configId': 'configDataId'">
        <template slot="isEnable" slot-scope="text">
          <span>
            <a-tag :color="text === 1? 'green' : 'volcano'">{{ isEnableType[text] }}</a-tag>
          </span>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <span>
            <a v-if="tableType === 'list'" @click="getDetail(record)">查看</a>
            <a-divider v-if="tableType === 'list'" type="vertical" />
            <a v-if="record.isEnable === 0" @click="switchEnable(record, 1)">启用</a>
            <a v-else @click="switchEnable(record, 0)">禁用</a>
            <a-divider type="vertical" />
            <a @click="editDictionary(record, index, 'edit')">编辑</a>
            <a-divider type="vertical" />
            <a @click="deteleDictionary(record)">删除</a>
          </span>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { allEnterpriseList } from '@/api/enterprise'

const listColumns = [
  { title: '字典名称', dataIndex: 'configName', key: 'configName', width: 250 },
  { title: '字典code', dataIndex: 'configCode', key: 'configCode', width: 250 },
  { title: '字典值', dataIndex: 'configValue', key: 'configValue', width: 250, ellipsis: true },
  { title: '企业名称', dataIndex: 'enterpriseName', key: 'enterpriseName', scopedSlots: { customRender: 'enterpriseName' }, width: 250 },
  { title: '备注', dataIndex: 'remark', key: 'remark', width: 250 },
  { title: '状态', dataIndex: 'isEnable', key: 'isEnable', scopedSlots: { customRender: 'isEnable' }, width: 80 },
  { title: '操作', key: 'action', scopedSlots: { customRender: 'action' }, width: 250 }
]
const detailColumns = [
  { title: '字典名称', dataIndex: 'configDataName', key: 'configDataName', width: 250 },
  { title: '字典code', dataIndex: 'configDataCode', key: 'configDataCode', width: 250 },
  { title: '字典值', dataIndex: 'configDataValue', key: 'configDataValue', width: 250, ellipsis: true },
  { title: '备注', dataIndex: 'remark', key: 'remark', width: 250 },
  { title: '状态', dataIndex: 'isEnable', key: 'isEnable', scopedSlots: { customRender: 'isEnable' }, width: 80 },
  { title: '操作', key: 'action', scopedSlots: { customRender: 'action' }, width: 250 }
]
export default {
  name: 'DictionaryList',
  props: {
    configList: {
      type: Array,
      default: () => []
    },
    tableType: {
      type: String,
      default: 'list'
    },
    configData: {
      type: Object,
      default: () => {
        return {
          configId: '',
          configName: '',
        }
      }
    }
  },
  data() {
    return {
      // data: [],
      columns: [],
      isEnableType: {
        '0': '禁用',
        '1': '启用'
      },
      parameter: {
        configName: '',
        configCode: '',
        enterpriseId: ''
      },
      dataSource: [],
      enterpriseList: {}
    }
  },
  watch: {
    tableType(value) {
      if (value === 'list') {
        this.columns = listColumns
      } else {
        this.columns = detailColumns
      }
    }
  },
  created() {
    this.getAllEnterpriseList()
    if (this.tableType === 'list') {
      this.columns = listColumns
    } else {
      this.columns = detailColumns
    }
  },
  methods: {
    // 企业列表
    getAllEnterpriseList() {
      allEnterpriseList().then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.dataSource = res.data.map((item) => {
          item.value = item.enterpriseId.toString()
          item.text = item.enterpriseName
          return item
        })
        this.enterpriseList = this.dataProcessing.listFormat(this.dataSource, 'enterpriseId', 'enterpriseName', 'object' )
      })
    },
    getDetail(record) {
      this.$emit('getDetail', record)
    },
    // 编辑
    editDictionary(record, index, type) {
      this.$emit('editDictionary', { record, index, type, dataSource: this.dataSource })
    },
    // 启用/禁用
    switchEnable(record, isEnable) {
      this.$emit('isEnable', { isEnable, parameter: this.parameter, record })
    },
    // 删除
    deteleDictionary(record) {
      const ids = []
      switch (this.tableType) {
        case 'list':
          ids.push(record.configId)
          break
        case 'detail':
          ids.push(record.configDataId)
          break
        default:
      }
      this.$emit('deleteDictionary', ids)
    },
    getBack() {
      this.$router.go(-1)
    },
    // 新增
    addDictionary() {
      this.$emit('addDictionary', { tableType: this.tableType, parameter: this.parameter, dataSource: this.dataSource })
    },
    // 搜索
    search(parameter) {
      this.$emit('search', parameter)
    },
    // 搜索
    reset() {
      this.parameter = {
        keyword : '',
        enterpriseId: ''
      }
      this.$emit('reset')
    },
    filterOption(inputValue, option) {
      return option.componentOptions.children[0].text.indexOf(inputValue.toLowerCase()) !== -1 || option.componentOptions.children[0].text.indexOf(inputValue.toUpperCase()) !== -1
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  .container-title{
    display: flex;
    justify-content: space-between;
  }
  .container-header{
    display: flex;
    justify-content: space-between;
  }
}
.w-200{
  width: 200px;
}
</style>
