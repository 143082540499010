<template>
  <div>
    <a-modal
      :title="title"
      :visible="addVisible"
      :confirm-loading="confirmLoading"
      @ok="(e) => handleOk(e, 'ruleForm')"
      @cancel="(e) => handleCancel(e, 'ruleForm')"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item v-if="tableType === 'list' && !editType" label="企业" prop="enterpriseId">
          <a-select v-model="form.enterpriseId" placeholder="请选择企业">
            <a-select-option v-for="item in dataSource" :key="item.enterpriseId">
              {{ item.enterpriseName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="tableType === 'list'" ref="configName" label="字典名称" prop="configName">
          <a-input v-model="form.configName" @blur="() => { $refs.configName.onFieldBlur() }" />
        </a-form-model-item>
        <a-form-model-item v-if="tableType === 'list'" ref="configCode" label="字典code" prop="configCode">
          <a-input v-model="form.configCode" :disabled="editType" @blur="() => { $refs.configCode.onFieldBlur() }" />
        </a-form-model-item>
        <a-form-model-item v-if="tableType === 'list'" ref="configValue" label="字典值" prop="configValue">
          <a-textarea v-model="form.configValue" @blur="() => { $refs.configValue.onFieldBlur() }" :rows="4" />
        </a-form-model-item>

        <a-form-model-item v-if="tableType === 'detail'" ref="configDataName" label="字典名字" prop="configDataName">
          <a-input v-model="form.configDataName" @blur="() => { $refs.configDataName.onFieldBlur() }" />
        </a-form-model-item>
        <a-form-model-item v-if="tableType === 'detail'" ref="configDataCode" label="字典code" prop="configDataCode">
          <a-input v-model="form.configDataCode" :disabled="editType" @blur="() => { $refs.configDataCode.onFieldBlur() }" />
        </a-form-model-item>
        <a-form-model-item v-if="tableType === 'detail'" ref="configDataValue" label="字典值" prop="configDataValue">
          <a-textarea v-model="form.configDataValue" @blur="() => { $refs.configDataValue.onFieldBlur() }" :rows="4"  />
        </a-form-model-item>

        <a-form-model-item ref="remark" label="备注" prop="remark">
          <a-textarea v-model="form.remark" @blur="() => { $refs.remark.onFieldBlur() }" :rows="4" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  configAdd,
  configUpdate,
  configDataAdd,
  configDataUpdate
} from '@/api/dictionary'
export default {
  name: 'DictionaryModal',
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    editType: {
      type: Boolean,
      default: false
    },
    tableType: {
      type: String,
      default: 'list'
    },
    title: {
      type: String,
      default: ''
    },
    form: {
      type: Object,
      default: () => {}
    },
    dataSource: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      confirmLoading: false,
      rules: {
        enterpriseId: [{ required: true, message: '请选择企业', trigger: 'change' }],
        configName: [{ required: true, message: '请输入字典名称', trigger: 'blur' }],
        configCode: [{ required: true, message: '请输入字典code', trigger: 'blur' }],
        configValue: [{ required: true, message: '请输入字典值', trigger: 'blur' }],
        isEnable: [{ required: true, message: '请选择状态', trigger: 'change' }],
        configDataName: [{ required: true, message: '请输入字典名字', trigger: 'blur' }],
        configDataCode: [{ required: true, message: '请输入字典code', trigger: 'blur' }],
        configDataValue: [{ required: true, message: '请输入字典值', trigger: 'blur' }],
        isEnabDatale: [{ required: true, message: '请选择状态', trigger: 'change' }]
      }
    }
  },
  methods: {
    showModal() {
      this.addVisible = true
    },
    handleOk(e, ruleForm) {
      // 表单校验
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const data = {...this.form}
          switch (this.tableType) {
            case 'list':
              if (this.editType) {
                configUpdate(data).then((res) => {
                  this.confirmLoading = false
                  if (res['code'] !== 0) {
                    this.$antMessage.error(res['message'])
                    return
                  }
                  this.$antMessage.success(res['message'])
                  this.$emit('visibleChange', false)
                  this.$emit('actionSuccess')
                })
              } else {
                configAdd(data).then((res) => {
                  this.confirmLoading = false
                  if (res['code'] !== 0) {
                    this.$antMessage.error(res['message'])
                    return
                  }
                  this.$antMessage.success(res['message'])
                  this.$emit('visibleChange', false)
                  this.$emit('actionSuccess')
                })
              }
              break
            case 'detail':
              if (this.editType) {
                configDataUpdate(data).then((res) => {
                  this.confirmLoading = false
                  if (res['code'] !== 0) {
                    this.$antMessage.error(res['message'])
                    return
                  }
                  this.$antMessage.success(res['message'])
                  this.$emit('visibleChange', false)
                  this.$emit('actionSuccess')
                })
              } else {
                configDataAdd(data).then((res) => {
                  this.confirmLoading = false
                  if (res['code'] !== 0) {
                    this.$antMessage.error(res['message'])
                    return
                  }
                  this.$antMessage.success(res['message'])
                  this.$emit('visibleChange', false)
                  this.$emit('actionSuccess')
                })
              }
              break
            default:
          }
        } else {
          return false
        }
      })
    },
    handleCancel(e, ruleForm) {
      // 取消校验
      this.$refs[ruleForm].resetFields()
      this.$emit('visibleChange', false)
    },
    filterOption(inputValue, option) {
      return option.componentOptions.children[0].text.indexOf(inputValue.toLowerCase()) !== -1 || option.componentOptions.children[0].text.indexOf(inputValue.toUpperCase()) !== -1
    }
  }
}
</script>

<style scoped>

</style>
