import axios from '@/utils/request'

/**
 * 一级字典列表
 * @data {*} data
 */
export function configList(data) {
  return axios({
    url: '/config/list',
    method: 'post',
    data
  })
}

/**
 * 新增一级字典
 * @data {*} data
 */
export function configAdd(data) {
  return axios({
    url: '/config/add',
    method: 'post',
    data
  })
}

/**
 * 禁用 || 启用一级字典
 * @data {*} data
 */
export function configIsEnable(data) {
  return axios({
    url: '/config/is/enable',
    method: 'post',
    data
  })
}

/**
 * 一级字典详情
 * @data {*} data
 */
export function configDetail(data) {
  return axios({
    url: '/config/detail',
    method: 'post',
    data
  })
}

/**
 * 修改一级字典信息
 * @data {*} data
 */
export function configUpdate(data) {
  return axios({
    url: '/config/update',
    method: 'post',
    data
  })
}

/**
 * 删除一级字典
 * @data {*} data
 */
export function configDelete(data) {
  return axios({
    url: '/config/delete',
    method: 'post',
    data
  })
}

/**
 * 获取二级字典列表
 * @data {*} data
 */
export function configDataList(data) {
  return axios({
    url: '/config-data/list',
    method: 'post',
    data
  })
}

/**
 * 新增二级字典
 * @data {*} data
 */
export function configDataAdd(data) {
  return axios({
    url: '/config-data/add',
    method: 'post',
    data
  })
}

/**
 * 禁用 || 启用二级字典
 * @data {*} data
 */
export function configDataIsEnable(data) {
  return axios({
    url: '/config-data/is/enable',
    method: 'post',
    data
  })
}

/**
 * 二级字典详情
 * @data {*} data
 */
export function configDataDetail(data) {
  return axios({
    url: '/config-data/detail',
    method: 'post',
    data
  })
}

/**
 * 修改二级字典信息
 * @data {*} data
 */
export function configDataUpdate(data) {
  return axios({
    url: '/config-data/update',
    method: 'post',
    data
  })
}

/**
 * 删除二级字典
 * @data {*} data
 */
export function configDataDelete(data) {
  return axios({
    url: '/config-data/delete',
    method: 'post',
    data
  })
}
